import PropTypes from "prop-types";
import React from "react";

import { Columns } from "./styles";

const Section = ({ children }) => {
  return <Columns>{children}</Columns>;
};

Section.propTypes = { children: PropTypes.object };

export default Section;
