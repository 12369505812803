import styled from "styled-components";

export const WorkTemplate = styled.div``;

export const Title = styled.h3``;

export const Meta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: ${props => `${props.theme.breakpoints.s}px`}) {
    flex-direction: column;
  }
`;

export const Date = styled.p`
  margin: 10px 0;
  font-weight: lighter;
  font-size: ${props => props.theme.fontSize.xs};
`;

export const Tech = styled.p`
  margin: 10px 0;
  color: ${props => props.theme.colors.midGrey};
  font-weight: lighter;
  font-size: ${props => props.theme.fontSize.xs};
`;

export const Copy = styled.div`
  .gatsby-resp-image-wrapper {
    max-width: 100% !important;
    margin: 40px auto !important;
    break-inside: avoid;
  }

  img {
    width: 100%;
  }
`;
