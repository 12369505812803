import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import rehypeReact from "rehype-react";

import Layout from "../../components/Layout";
import Link from "../../components/Link";
import Section from "../../components/Section";
import { Copy, Date, Meta, Tech, Title, WorkTemplate } from "./styles";

const Template = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, htmlAst } = markdownRemark;

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { a: Link, section: Section }
  }).Compiler;

  return (
    <Layout>
      <WorkTemplate>
        <>
          <Title>{frontmatter.title}</Title>
          <Meta>
            <Date>{frontmatter.date}</Date>
            <Tech>
              {"Tech stack: "}
              <span>{frontmatter.tech}</span>
            </Tech>
          </Meta>

          <Copy>{renderAst(htmlAst)}</Copy>
        </>
      </WorkTemplate>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM, YYYY")
        slug
        title
        tech
      }
    }
  }
`;

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      htmlAst: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        tech: PropTypes.string.isRequired
      }).isRequired
    })
  })
};

export default Template;
