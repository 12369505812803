import styled from "styled-components";

export const Columns = styled.div`
  margin-bottom: 40px;
  text-align: justify;
  column-count: 2;
  column-gap: 40px;

  p {
    margin: 0 0 12px 0;
    font-size: ${props => props.theme.fontSize.xs};
  }

  @media (max-width: ${props => `${props.theme.breakpoints.s}px`}) {
    column-count: 1;
  }
`;
